
/* ----------------------------------------------------------------

	footer.scss

-----------------------------------------------------------------*/

/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/

#footer {
	position: relative;
	background-color: #EEE;
	border-top: 5px solid rgba(0,0,0,0.2);
	.footer-widgets-wrap {
		position: relative;
		padding: 70px 0;
	}
}

.device-lg,
.device-md {
	&.sticky-footer {
		#top-bar { z-index: 299; }
		#header { z-index: 199; }
		#slider,
		#page-submenu,
		#page-title,
		#content,
		#slider,
		#page-submenu,
		#page-title,
		#content { z-index: 2; }
		#footer {
			position: fixed;
			top: auto;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
		&:not(.stretched) #footer {
			left: 50%;
			margin-left: -610px;
			width: 1220px;
		}
	}
}

.device-md.sticky-footer:not(.stretched) #footer {
	margin-left: -500px;
	width: 1000px;
}

.footer-widgets-wrap {
	.col_full,
	.col_half,
	.col_one_third,
	.col_two_third,
	.col_three_fourth,
	.col_one_fourth,
	.col_one_fifth,
	.col_two_fifth,
	.col_three_fifth,
	.col_four_fifth,
	.col_one_sixth,
	.col_five_sixth {
		margin-bottom: 0;
	}
}

#copyrights {
	padding: 5px 0;
	background-color: #DDD;
	font-size: $font-size-base;
	line-height: $line-height-content;
	i {
		&.footer-icon {
			position: relative;
			top: 1px;
			font-size: $font-size-base;
			width: 14px;
			text-align: center;
			margin-right: 3px;
		}
	}
	.col_half {
		margin-bottom: 0 !important;
	}
	a {
		&:first-child {
			margin-left: 0;
		}
	}
	.tright {
		a {
			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.copyright-links {
	a {
		display: inline-block;
		margin: 0 3px;
		color: #333;
		border-bottom: 1px dotted #444;
		&:hover {
			color: #555;
			border-bottom: 1px solid #666;
		}
	}
}

.copyrights-menu {
	margin-bottom: 10px;
	a {
		font-size: $font-size-base;
		margin: 0 10px;
		border-bottom: 0 !important;
	}
}

.footer-logo {
	display: block;
	margin-bottom: 15px;
	max-height: 60px;
}