/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 4.5.1

    LESS Stylesheet

-----------------------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans');

// Bootstrap
@import 'bootstrap';

// Initialize
@import 'sass/variables';
@import 'sass/mixins';


// Core CSS
@import 'sass/typography';
@import 'sass/helpers';
@import 'sass/layouts';


// Content Blocks
@import 'sass/topbar';
@import 'sass/header';
@import 'sass/sliders';
@import 'sass/pagetitle';
@import 'sass/content';
@import 'sass/portfolio';
@import 'sass/blog';
@import 'sass/shop';
@import 'sass/events';


// Shortcodes
@import 'sass/shortcodes';


// Footer
@import 'sass/footer';


// Widgets
@import 'sass/widgets';


// Extras
@import 'sass/extras';

// Dark
@import 'sass/dark';

// Responsive
@import 'sass/responsive';

//CSS Plugins
@import 'css/animate';
@import 'css/font-icons';
@import 'css/spinners';
@import 'css/swiper';
@import '../vendor/featherlight/featherlight.min.css';
@import '../vendor/summernote/summernote.css';
@import 'css/select-boxes';
@import 'css/bs-switches';
@import 'css/radio-checkbox';

// Site Custom
@import 'argo';
